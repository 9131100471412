import React from 'react';
import styled from 'styled-components';
import { useAPIData } from 'dal/useAPIData';

import Icon from 'components/icon';
import Button from 'components/button';

import { PopoverFilters } from './filters-popover';
import SearchInput from './search-input';

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid #ddd;
`;

const Filter = styled(Button)`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 0 15px;
  gap: 10px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  height: 45px;
  line-height: 1.4;
  .filterBody {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    flex: 1;
  }
  .filterLabel {
    font-weight: bold;
  }
  .filterValue {
    font-size: 12px;
    tex-align: left;
  }
  &:hover {
    background-color: #f5f5f5;
  }
  &.active {
    border-color: #00a1ff;
    background-color: #e5f6ff;
  }
`;

const controls = [
  { label: 'Bed & Bath', controls: ['beds', 'baths'], id: 'bedsBaths' },
  { label: 'Price', controls: ['price'], id: 'price' },
  { label: 'Home Type', controls: ['homeType'], id: 'homeType' },
  { label: 'Pets', controls: ['petsAllowed'], id: 'petsAllowed' },
  { label: 'More', controls: ['restrictToMap', 'moveInDate', 'amenities'], id: 'more' }
];

const getFilterLabel = (filter, filters) => {
  if (filter.id === 'bedsBaths') {
    return filters.beds && filters.baths
      ? 'Bed & Baths (2)'
      : filters.beds || filters.baths
      ? 'Bed & Baths (1)'
      : 'Bed & Baths';
  }
  if (filter.id === 'homeType') {
    return `Home Type ${filters.homeType.length > 0 ? `(${filters.homeType.length})` : ''}`;
  }
  if (filter.id === 'petsAllowed') {
    return `Pets ${filters.petsAllowed ? `(Allowed)` : ''}`;
  }
  if (filter.id === 'more') {
    const filterCount = [
      !!filters.moveInDate,
      filters.amenities.length > 0,
      !!filters.restrictToMap
    ].filter((t) => t).length;

    return `More${filterCount ? ` (${filterCount})` : ''}`;
  }
  if (filter.id === 'price') {
    return !filters.price.min && !filters.price.max
      ? 'Price'
      : filters.price.min && filters.price.max
      ? `Price $${filters.price.min} - $${filters.price.max}`
      : filters.price.min && !filters.price.max
      ? `Price min $${filters.price.min}`
      : filters.price.max && !filters.price.min
      ? `Price max $${filters.price.max}`
      : 'Price';
  }
};

export default ({ orgInfo, filters, setFilters, location, setLocation, orgId }) => {
  const handleFilters = (update, filter) => {
    setFilters((prev) => ({
      ...prev,
      ...filter.controls.reduce((obj, key) => ({ ...obj, [key]: update[key] }), {})
    }));
  };

  return (
    <Wrapper>
      <SearchInput value={location} onChange={(location) => setLocation(location)} />
      {controls.map((filter, i) => (
        <PopoverFilters
          key={i}
          orgInfo={orgInfo}
          value={filters}
          orgId={orgId}
          onApply={(filters) => handleFilters(filters, filter)}
          controls={filter.controls}>
          {(provided) => (
            <Filter className={provided.open ? 'active' : ''} {...provided}>
              <div className="filterBody">
                <div className="filterLabel">{getFilterLabel(filter, filters)}</div>
              </div>
              <Icon name="fa-duotone fa-solid fa-chevron-down" />
            </Filter>
          )}
        </PopoverFilters>
      ))}
    </Wrapper>
  );
};
