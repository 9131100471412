import React from 'react';
import styled from 'styled-components';
import Shape from 'components/skeleton-shape';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Map = styled.div`
  flex: 1;
  margin: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const List = styled.div`
  width: 620px;
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 580px) {
    grid-template-columns: 1fr;
  }
  div {
    height: 300px;
  }
`;

export default () => {
  return (
    <Wrapper>
      <Map>
        <Shape width="100%" height="100%" borderRadius="10px" />
      </Map>
      <List>
        <Shape borderRadius="10px" />
        <Shape borderRadius="10px" />
        <Shape borderRadius="10px" />
      </List>
    </Wrapper>
  );
};
