import { useAPIData } from 'dal/useAPIData';

export default ({ locationId }) => {
  const [apiData] = useAPIData(
    'context.LOAD_SCHOOLS',
    !locationId ? undefined : { locationId: locationId, distance: 25, limit: 10 },
    { disableEmptyArgs: true, reFetchWhen: [locationId] }
  );

  const schools = apiData?.data?.content?.schools;

  return { schools: schools };
};
