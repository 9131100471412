import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import Header from './header';
import Gallery from './gallery';
import Detail from './detail';
import { useMediaQuery } from 'react-responsive';
import { useProperty } from './hooks';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './loading-screen';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  line-height: 1.3;
  *:not(i) {
    box-sizing: border-box;
  }
`;

const Body = styled.div`
  flex: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const FailedWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
`;
const Property = ({ orgId, locationId, onClose, locations = [] }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { location, loading, nearbyLocations, nearbySchools, failed } = useProperty({
    locationId,
    orgId
  });
  const navigate = useNavigate();

  const handleNextBack = (dir) => {
    const currentIndex = locations.findIndex((p) => p._id === locationId);
    const nextIndex = currentIndex < 0 ? 0 : dir === 'next' ? currentIndex + 1 : currentIndex - 1;
    const nextProp = locations[nextIndex] || locations[0];
    navigate(`/search/${orgId}/${nextProp?._id}`);
  };

  return (
    <Wrapper>
      <Header
        onClose={onClose}
        onNextBack={handleNextBack}
        location={location}
        showNavButtons={locations.length > 0}
      />
      {!failed && loading && <LoadingScreen />}
      {failed && <FailedWrapper>Property is no longer available</FailedWrapper>}
      {!loading && !failed && location?._id === locationId && (
        <Body>
          {!isMobile && <Gallery location={location} />}
          <Detail
            location={location}
            nearbyLocations={nearbyLocations}
            nearbySchools={nearbySchools}
          />
        </Body>
      )}
    </Wrapper>
  );
};

export default (props) => {
  return (
    <Modal
      width="1200px"
      height="98%"
      maxHeight="100%"
      radius={0}
      onClose={props.onClose}
      hideClose
      open>
      <Property {...props} />
    </Modal>
  );
};
