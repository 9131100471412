import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import { filterControls } from './filters/constants';

const Wrapper = styled.div`
  background-color: #294a68;
  color: #fff;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  button {
    font-size: 14px;
    padding: 15px 12px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
  }
`;

export default ({ tab, onClick, filters }) => {
  const filtersCount = Object.keys(filters).filter((key) =>
    filterControls[key]?.isActive(filters)
  ).length;
  const filtersLabel = filtersCount > 0 ? `FILTERS (${filtersCount})` : 'FILTERS';

  return (
    <Wrapper>
      <Button onClick={() => onClick('search')}>
        <Icon name="fa-duotone fa-solid fa-magnifying-glass" /> SEARCH
      </Button>
      <Button onClick={() => onClick(tab === 'map' ? 'list' : 'map')}>
        <Icon
          name={
            tab === 'map'
              ? 'fa-duotone fa-solid fa-bars-staggered'
              : 'fa-duotone fa-solid fa-map-location-dot'
          }
        />
        {tab === 'map' ? 'LIST' : 'MAP'}
      </Button>
      <Button onClick={() => onClick('filters')}>
        <Icon name="fa-duotone fa-solid fa-filter-list" /> {filtersLabel}
      </Button>
    </Wrapper>
  );
};
