import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import Image from 'components/image';

const Slider = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #eee;
  .smartImage {
    width: 100%;
    height: 100%;
  }
  button {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.6);
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #000;
    &:first-child {
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child {
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .imageCount {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 3px 12px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
  }
  &:hover {
    .imageCount {
      display: block;
    }
    button {
      display: flex;
    }
  }
`;

export default ({ photos = [] }) => {
  const [currentPos, setCurrentPos] = React.useState(0);

  const handleNextPrev = (dir) => {
    const nextPos = dir === 'next' ? currentPos + 1 : currentPos - 1;
    setCurrentPos(nextPos === photos.length ? 0 : nextPos < 0 ? photos.length - 1 : nextPos);
  };

  return (
    <Slider>
      <Image url={photos[currentPos]} fallback="/assets/location-placeholder2.png" />
      {photos.length > 1 && (
        <>
          <div>
            <Button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleNextPrev('prev');
              }}>
              <Icon name="fa-solid fa-arrow-left" />
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleNextPrev('next');
              }}>
              <Icon name="fa-solid fa-arrow-right" />
            </Button>
          </div>
          <div className="imageCount">
            {currentPos + 1}/{photos.length}
          </div>
        </>
      )}
    </Slider>
  );
};
