import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 35px 0;
  padding: 0 20px;
  .title {
    margin-bottom: 10px;
    font-weight: bold;
  }
`;

export default ({ label, children, id }) => {
  return (
    <Wrapper id={id}>
      <div className="title">{label}</div>
      <div className="body">{children}</div>
    </Wrapper>
  );
};
