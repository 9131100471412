import React from 'react';
import { useAPIData } from 'dal/useAPIData';
import { displayLocationInfo } from 'helpers/location';

// ~1 mile of lat and lon in degrees
const MILE_LAT = 0.0144927536231884;
const MILE_LNG = 0.0181818181818182;
const DISTANCE_MILES = 25;
const DISTANCE_LAT = MILE_LAT * DISTANCE_MILES;
const DISTANCE_LNG = MILE_LNG * DISTANCE_MILES;

// This won't work properly if you are in the middle of the pacific & in some other spots, but good enough for standard usage
// Long term we will swap to proper geo indexes in DB, but we aren't configured for it yet
const createBoxFromPoint = (pnt) => {
  return {
    north: pnt.lat + DISTANCE_LAT,
    south: pnt.lat - DISTANCE_LAT,
    east: pnt.lng + DISTANCE_LNG,
    west: pnt.lng - DISTANCE_LNG
  };
};

export default ({ address, locationId, orgId }) => {
  const { locationBox, missingData, centerPoint } = React.useMemo(() => {
    const missingData = !address?.geopoint?.[0] || !address?.geopoint?.[1];
    const centerPoint = {
      lat: address?.geopoint?.[1],
      lng: address?.geopoint?.[0]
    };
    const locationBox = missingData ? undefined : createBoxFromPoint(centerPoint);
    return { locationBox, missingData, centerPoint };
  }, [address]);
  const [visibleLocations, setVisibleLocations] = React.useState([]);

  useAPIData(
    'context.LOAD_LISTINGS',
    missingData
      ? undefined
      : {
          orgId: orgId,
          filters: {
            omit: [locationId],
            centerLat: centerPoint?.lat,
            centerLng: centerPoint?.lng,
            includeDistance: true,
            geoBounds: [
              locationBox?.north,
              locationBox?.east,
              locationBox?.south,
              locationBox?.west
            ],
            page: 0,
            size: 10
          }
        },
    {
      disableEmptyArgs: true,
      reFetchWhen: [locationBox],
      onSuccess: (val) => {
        // console.log('loaded - nearby homes');
        const mappedLocations = (val?.data || []).map((l) => displayLocationInfo(l));
        setVisibleLocations(mappedLocations);
      }
    }
  );

  return { locations: visibleLocations };
};
