import React from 'react';
import styled from 'styled-components';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { primary } from 'styles/colors';
import Icon from 'components/icon';

const Bubble = styled.div`
  padding: 10px 12px;
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  &.active {
    background-color: #00a1ff;
  }
`;

const Content = ({ type, title, details, onClick }) => {
  if (type === 'primary') {
    return (
      <Icon size={18} name="fa-solid fa-star" color={primary} title={title} onClick={onClick} />
    );
  }

  if (type === 'school') {
    return <Icon size={18} name="fa-solid fa-school-flag" title={title} />;
  }

  if (type === 'rental') {
    return <Icon size={18} name="fa-solid fa-house" title={title} />;
  }

  return <Bubble>{title || details}</Bubble>;
};

export default ({ position, onClick, ...props }) => {
  const handleClick = React.useCallback(() => onClick?.({ ...props }), []);

  return (
    <AdvancedMarker position={position} onClick={handleClick}>
      <Content {...props} />
    </AdvancedMarker>
  );
};
