import React from 'react';
import styled from 'styled-components';
import ShadowRender from 'components/shadow-render';

const Wrapper = styled.div`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px 0px;
  border: 1px solid #ddd;
  padding: 20px 40px;
  display: grid;
  gap: 15px;
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .feature {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
const Content = styled.div`
  all: initial;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1.2;
  word-break: break-word;
  p {
    margin: 0;
  }
  ul {
    padding-left: 1.5em !important;
    list-style-type: disc !important;
    list-style-position: inside;
    > li {
      list-style-type: disc !important;
      list-style-position: inside;
    }
  }
`;

export default ({ location }) => {
  if (!location?.aptlyListings?.benefits) return <></>;
  return (
    <Wrapper>
      <Content>
        <ShadowRender html={location?.aptlyListings?.benefits} />
      </Content>
    </Wrapper>
  );
};
