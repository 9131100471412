import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    opacity:1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity:1;
  }
`;

export const Shape = styled.div`
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '15px' }) => height};
  background-color: ${({ background = '#f5f5f5' }) => background};
  border-radius: ${({ borderRadius = '25px' }) => borderRadius};
  animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom};
  margin-right: ${({ marginRight = 0 }) => marginRight};
  margin-top: ${({ marginTop = 0 }) => marginTop};
  margin-left: ${({ marginLeft = 0 }) => marginLeft};
`;

export default Shape;
