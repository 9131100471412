import React from 'react';
import styled from 'styled-components';
import { useAPIData } from 'dal/useAPIData';

import Modal from 'components/modal';
import Button from 'components/button';
import FormGroup from 'components/form-group';
import InlineSelect from 'components/inline-select';
import InputRange from 'components/input-range';
import Checkbox from 'components/checkbox';
import InputDate from 'components/input-date';
import Popover from 'components/popover';
import MobileHeader from 'components/mobile-header';
import { homeTypeOpts, bedsBathsOpts, filterControls, defaultFilters } from './constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  min-width: 350px;
  box-sizing: border-box;
  background-color: #fafafa;
  * {
    box-sizing: border-box;
  }
  .group-label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  input {
    background-color: #fff;
    border-radius: 4px;
  }
  &.filtersFullView {
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 16px;
    .filtersBody {
      padding: 20px;
      overflow-y: auto;
    }
    .filtersFooter {
      padding: 10px;
      margin: 0;
    }
  }
`;

const Body = styled.div`
  flex: 1;
`;

const OptionsWrapper = styled.div`
  display: grid;
  gap: 10px;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Apply = styled(Button)`
  width: 100%;
  height: 40px;
  background-color: #00a1ff;
`;

const Clear = styled(Button)`
  width: 100%;
  height: 40px;
  color: #00a1ff;
`;

const addRemoveFromArray = (arr, value) => {
  return arr.includes(value) ? arr.filter((item) => item !== value) : [...arr, value];
};

const Filters = ({ orgInfo, controls = [], value = {}, isModal, onClose, onApply, closeMenu }) => {
  const [filters, setFilters] = React.useState({
    ...defaultFilters,
    ...value
  });

  const updateFilters = (update) => setFilters((prev) => ({ ...prev, ...update }));
  const hasFilter = (filter) => {
    if (controls.length == 0) return true;
    return controls.includes(filter);
  };

  const handleApply = () => {
    onApply(filters);
    onClose?.();
    closeMenu?.();
  };

  const handleClear = () => {
    setFilters(defaultFilters);
    onApply(defaultFilters);
    onClose?.();
    closeMenu?.();
  };

  const showClearAll = () => {
    if (controls.length === 0) {
      return Object.keys(filters).some((key) => filterControls[key]?.isActive(filters));
    }
    return controls.some((key) => filterControls[key].isActive(filters));
  };

  const amenities = orgInfo?.aptlyListings?.amenities || [];

  return (
    <Wrapper className={isModal ? 'filtersFullView' : ''}>
      {isModal && <MobileHeader title="Filters" onBack={onClose} />}
      <Body className="filtersBody">
        {hasFilter('beds') && (
          <FormGroup label="Bedrooms">
            <InlineSelect
              value={filters.beds}
              onChange={(option) => updateFilters({ beds: option.value })}
              options={bedsBathsOpts}
            />
          </FormGroup>
        )}
        {hasFilter('baths') && (
          <FormGroup label="Bathrooms">
            <InlineSelect
              options={bedsBathsOpts}
              value={filters.baths}
              onChange={(option) => updateFilters({ baths: option.value })}
            />
          </FormGroup>
        )}
        {hasFilter('price') && (
          <FormGroup label="Price">
            <InputRange
              value={filters.price}
              onChange={(range) => updateFilters({ price: range })}
              autoFocus={controls.length === 1 && controls[0] === 'price'}
            />
          </FormGroup>
        )}
        {hasFilter('homeType') && (
          <FormGroup label="Home type">
            <OptionsWrapper>
              {homeTypeOpts.map((option, i) => (
                <Checkbox
                  key={i}
                  label={option.label}
                  checked={filters.homeType.includes(option.value)}
                  onChange={() =>
                    updateFilters({
                      homeType: addRemoveFromArray(filters.homeType, option.value)
                    })
                  }
                />
              ))}
            </OptionsWrapper>
          </FormGroup>
        )}
        {hasFilter('restrictToMap') && (
          <FormGroup label="Map Options">
            <OptionsWrapper>
              <Checkbox
                label={'Only include properties in the map bounds'}
                checked={!!filters.restrictToMap}
                onChange={() => updateFilters({ restrictToMap: !filters.restrictToMap })}
              />
            </OptionsWrapper>
          </FormGroup>
        )}
        {hasFilter('petsAllowed') && (
          <FormGroup label="Pets">
            <OptionsWrapper>
              <Checkbox
                label={'Pets Allowed'}
                checked={!!filters.petsAllowed}
                onChange={() => updateFilters({ petsAllowed: !filters.petsAllowed })}
              />
            </OptionsWrapper>
          </FormGroup>
        )}
        {hasFilter('moveInDate') && (
          <FormGroup
            label="Move-In Date"
            subText="Select a date to view listings available on or before this date.">
            <InputDate
              value={filters.moveInDate}
              onChange={(event) => updateFilters({ moveInDate: event.target.value })}
            />
          </FormGroup>
        )}
        {!!amenities?.length && hasFilter('amenities') && (
          <FormGroup label="Amenities">
            <OptionsWrapper>
              {amenities?.map((option, i) => (
                <Checkbox
                  key={i}
                  label={option}
                  checked={filters.amenities.includes(option)}
                  onChange={() =>
                    updateFilters({
                      amenities: addRemoveFromArray(filters.amenities, option)
                    })
                  }
                />
              ))}
            </OptionsWrapper>
          </FormGroup>
        )}
      </Body>
      <Footer className="filtersFooter">
        {showClearAll() && <Clear onClick={handleClear}>Clear</Clear>}
        <Apply className="applyBtn" variant="primary" radius="4px" onClick={handleApply}>
          APPLY
        </Apply>
      </Footer>
    </Wrapper>
  );
};

export const PopoverFilters = ({ children, ...props }) => {
  return (
    <Popover placeholder={children}>{(provided) => <Filters {...props} {...provided} />}</Popover>
  );
};

export const ModalFilters = (props) => {
  return (
    <Modal open width="500px" height="auto" radius={0}>
      <Filters {...props} isModal />
    </Modal>
  );
};
