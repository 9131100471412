import React from 'react';
import styled from 'styled-components';
import Section from './section';
import Icon from 'components/icon';
import Button from 'components/button';
import { darken, cssVar } from 'polished';
import Modal, { ModalHeader, ModalScrollable } from 'components/modal';
import { previewFile, fileIconFromName } from 'utils/fileHelpers';
import { UserContext } from 'state';
import MultiUpload from '../multi-upload';
import { Trans } from 'react-i18next';

const Row = styled.div`
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
`;

const Attachment = styled.div`
  display: flex;
  min-height: 20px;
  gap: 10px;
  align-items: center;
  font-size: 0.75em;
  .title {
    font-size: 0.88em;
    font-weight: bold;
  }
  color: ${cssVar('--color-font-primary', '#1d3843')};
  &:hover {
    color: ${darken(0.4, cssVar('--color-link', '#007cff'))};
  }
`;

const AttachmentList = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;

export const FileModal = ({ onClose, application, t }) => (
  <Modal closeOnOutsideClick={false} open={true} onClose={onClose} width="600px" height="70%">
    <ModalHeader>
      <Trans i18nKey="summary.files.add_additional">Add Additional Verification Documents</Trans>
    </ModalHeader>
    <ModalScrollable>
      <MultiUpload
        t={t}
        application={application}
        hideExisting={true}
        field="other"
        maxFiles={20}
        referenceId={'other'}
      />
    </ModalScrollable>
  </Modal>
);

const FileGroup = ({ fileGroups, groupId, onPreview, t }) => {
  const GROUP_NAMES = {
    animal: <Trans i18nKey="summary.files.animal">Animal Documents</Trans>,
    animalPhotos: <Trans i18nKey="summary.files.animalPhotos">Animal Photos</Trans>,
    bank: <Trans i18nKey="summary.files.bank">Bank Statements</Trans>,
    pay: <Trans i18nKey="summary.files.pay">Pay Stubs</Trans>,
    tax: <Trans i18nKey="summary.files.tax">Tax Documents</Trans>,
    identity: <Trans i18nKey="summary.files.identity">Identity Documents</Trans>,
    section8: <Trans i18nKey="summary.files.section8">Section 8 Documents</Trans>,
    other: <Trans i18nKey="summary.files.other">Other Documents</Trans>
  };

  if (!fileGroups[groupId]?.length) return <></>;

  return (
    <Row>
      <div>{GROUP_NAMES[groupId]}</div>
      <AttachmentList>
        {fileGroups[groupId].map((fl, ix) => {
          return (
            <Button variant="link" key={ix}>
              <Attachment onClick={() => onPreview(fl)}>
                <Icon name={fileIconFromName(fl.name)} size={25} />
                <div className="content">
                  <div className="title">{fl.name}</div>
                </div>
              </Attachment>
            </Button>
          );
        })}
      </AttachmentList>
    </Row>
  );
};

export default ({ application, onRefresh, setLoading, t }) => {
  const { userContext } = React.useContext(UserContext);
  const [showFileUpload, setShowFileUpload] = React.useState();

  const fileGroups = React.useMemo(() => {
    let fg = ['bank', 'pay', 'tax', 'other'].reduce((f, c) => {
      let matchingKeys = Object.keys(application.files || []).filter((f) => f?.startsWith(c));
      return Object.assign(f, {
        [c]: matchingKeys.reduce(
          (cFiles, cFile) => cFiles.concat(application.files?.[cFile]).filter((z) => z),
          (application.fileRefs?.filter((f) => f?.type === c) || []).map((z) => ({
            ...z,
            allowDelete: true
          }))
        )
      });
    }, {});

    fg.identity = [application.files?.govFront, application.files?.govBack]
      .concat(
        application.fileRefs
          ?.filter((f) => f?.type === 'identity')
          .map((z) => ({
            ...z,
            allowDelete: true
          }))
      )
      .filter((g) => g);
    fg.section8 = Object.values(application.files?.section8 || {});
    fg.animal = application.fileRefs
      ?.filter((f) => f.type === 'animal')
      .map((fr) =>
        Object.assign(fr, { item: application.pets?.find((u) => u.uuid === fr.referenceId) })
      );
    return fg;
  }, [application]);

  const onPreview = (file) => {
    setLoading(true);
    previewFile({
      userContext,
      locationId: application.locationId,
      applicationId: application._id,
      file
    }).then(() => setLoading(false));
  };

  return (
    <Section title={<Trans i18nKey="summary.files.title">Attached Files</Trans>}>
      <FileGroup fileGroups={fileGroups} t={t} onPreview={onPreview} groupId="bank" />
      <FileGroup fileGroups={fileGroups} t={t} onPreview={onPreview} groupId="pay" />
      <FileGroup fileGroups={fileGroups} t={t} onPreview={onPreview} groupId="tax" />
      <FileGroup fileGroups={fileGroups} t={t} onPreview={onPreview} groupId="identity" />
      <FileGroup fileGroups={fileGroups} t={t} onPreview={onPreview} groupId="section8" />
      <FileGroup fileGroups={fileGroups} t={t} onPreview={onPreview} groupId="animal" />
      <FileGroup fileGroups={fileGroups} t={t} onPreview={onPreview} groupId="other" />
      <Row>
        <Button variant="link" onClick={() => setShowFileUpload(true)}>
          <Icon name="fa-duotone fa-file-arrow-up" size={18} />{' '}
          <Trans i18nKey="summary.files.add_file">+ Attach Files</Trans>
        </Button>
      </Row>
      {showFileUpload && (
        <FileModal
          t={t}
          application={application}
          onClose={() => {
            setShowFileUpload(false);
            onRefresh && onRefresh();
          }}
        />
      )}
    </Section>
  );
};
