import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import Section from './section';

const Wrapper = styled.div`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px 0px;
  border: 1px solid #ddd;
  font-size: 14px;
`;

const RowData = styled.div`
  display: flex;
  line-height: 1.8;
  gap: 10px;
  div {
    flex: 1;
  }
`;

const Row = styled.div`
  margin: 15px 0px;
  padding: 0 20px;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;

  button {
    padding: 5px;
    color: #1d3843;
    &:hover {
      color: #888;
    }
    &.active {
      color: #1d3843;
      font-weight: bold;
      border-bottom: 2px solid #00a1ff;
    }
  }
`;
const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
export const toMoneyString = (val) => {
  let dollarVal = val;
  if (val?.amount) dollarVal = val.amount / 100;

  return dollarVal === undefined ? undefined : moneyFormatter.format(dollarVal);
};

const useStandardizedData = ({ location }) => {
  const depositFee = location?.deposit?.amount ? location?.deposit?.amount / 100 : undefined;
  const applicationFee = location?.applicationFee?.amount
    ? location?.applicationFee?.amount / 100
    : undefined;
  const leaseCharges = location?.aptlyListings?.representativeFees;
  const zeroDepositDisplayRate = location?.aptlyListings?.zeroDepositEnabled
    ? location?.aptlyListings?.zeroDepositDisplayRate
    : undefined;
  const zeroDepositEnabled =
    !!zeroDepositDisplayRate && !!location?.aptlyListings?.zeroDepositEnabled;
  const useRanges = !!location?.listingsUseRanges;
  const baseRent = location?.marketRent?.amount ? location?.marketRent?.amount / 100 : undefined;
  const baseRentMin = location?.rentMin?.amount ? location?.rentMin?.amount / 100 : undefined;
  const baseRentMax = location?.rentMax?.amount ? location?.rentMax?.amount / 100 : undefined;
  const [tab, setTab] = React.useState(zeroDepositEnabled ? 'zeroDeposit' : 'withDeposit');

  const { monthlyCharges, moveInCharges, totalMoveIn, totalMonthly, totalMonthlyMax } =
    React.useMemo(() => {
      const monthlyCharges = leaseCharges?.filter((lc) => lc.type === 'monthly') || [];
      const moveInCharges = leaseCharges?.filter((lc) => lc.type === 'moveIn') || [];
      const totalMonthly = monthlyCharges.reduce(
        (f, c) => (!c?.amount?.amount ? f : f + c?.amount?.amount / 100),
        (useRanges ? baseRentMin : baseRent) || 0
      );
      const totalMonthlyMax =
        baseRentMax && useRanges
          ? monthlyCharges.reduce(
              (f, c) => (!c?.amount?.amount ? f : f + c?.amount?.amount / 100),
              (useRanges ? baseRentMax : baseRent) || 0
            )
          : undefined;
      const totalMoveIn = moveInCharges.reduce(
        (f, c) => (!c?.amount?.amount ? f : f + c?.amount?.amount / 100),
        applicationFee || 0
      );

      return {
        monthlyCharges,
        moveInCharges,
        totalMoveIn: tab === 'withDeposit' ? (depositFee || 0) + totalMoveIn : totalMoveIn,
        totalMonthly:
          tab === 'zeroDeposit' ? (zeroDepositDisplayRate || 0) + totalMonthly : totalMonthly,
        totalMonthlyMax:
          tab === 'zeroDeposit' ? (zeroDepositDisplayRate || 0) + totalMonthlyMax : totalMonthlyMax
      };
    }, [location, tab]);

  const displayRent = useRanges
    ? baseRentMin !== baseRentMax && baseRentMin && baseRentMax
      ? [baseRentMin, baseRentMax]
          .filter((z) => z)
          .map((z) => toMoneyString(z))
          .join(' - ')
      : toMoneyString(baseRentMin || baseRentMax)
    : toMoneyString(baseRent);

  return {
    monthlyCharges,
    moveInCharges,
    totalMoveIn,
    displayTotalMonthly:
      useRanges && totalMonthly !== totalMonthlyMax
        ? [totalMonthly, totalMonthlyMax]
            .filter((z) => z)
            .map((z) => toMoneyString(z))
            .join(' - ')
        : toMoneyString(totalMonthly),
    zeroDepositDisplayRate: zeroDepositDisplayRate,
    zeroDepositEnabled: zeroDepositEnabled,
    leaseCharges: location?.aptlyListings?.representativeFees,
    applicationFee,
    depositFee,
    displayRent,
    hasRent: useRanges ? [baseRentMin, baseRentMax].some((z) => !!z) : !!baseRent,
    tab,
    setTab
  };
};

const FeeDetails = ({ location = {} }) => {
  const {
    tab,
    setTab,
    hasRent,
    leaseCharges,
    zeroDepositEnabled,
    zeroDepositDisplayRate,
    monthlyCharges,
    displayTotalMonthly,
    depositFee,
    applicationFee,
    moveInCharges,
    totalMoveIn,
    displayRent
  } = useStandardizedData({ location });

  if (!leaseCharges?.length) return <></>;

  return (
    <Section label="FEES" id="fees">
      <Wrapper className="feeWrapper">
        {zeroDepositEnabled && (
          <Row>
            <Tabs>
              <Button
                className={tab === 'withDeposit' ? 'active' : ''}
                onClick={() => setTab('withDeposit')}>
                With Deposit
              </Button>
              <Button
                className={tab === 'zeroDeposit' ? 'active' : ''}
                onClick={() => setTab('zeroDeposit')}>
                Zero Deposit
              </Button>
            </Tabs>
          </Row>
        )}
        <Row>
          {!!hasRent && (
            <RowData>
              <div className="dataLabel">Base Rent</div>
              <div>{displayRent}</div>
            </RowData>
          )}
          {tab === 'zeroDeposit' && (
            <RowData>
              <div className="dataLabel">
                Zero Deposit Fee{' '}
                <Icon
                  name="fa-duotone fa-solid fa-circle-info"
                  title="Qualified renters have the convenience of paying a low, nonrefundable fee instead of an upfront deposit. "
                />
              </div>{' '}
              <div>Starting at {toMoneyString(zeroDepositDisplayRate)}</div>
            </RowData>
          )}
          {monthlyCharges.map((lc, ix) => {
            return (
              <RowData key={ix}>
                <div className="dataLabel">{lc.title}</div> <div>{toMoneyString(lc.amount)}</div>
              </RowData>
            );
          })}

          <RowData>
            <div className="dataLabel">
              <strong>EST. MONTHLY COST</strong>
            </div>
            <div>
              <strong>
                {displayTotalMonthly}
                /month
              </strong>
              {!hasRent ? <i> (excludes rent)</i> : <></>}
            </div>
          </RowData>
        </Row>
        <Row>
          <RowData>
            <div className="dataLabel">
              <strong>ONE TIME COSTS</strong>
            </div>
            <div />
          </RowData>
          {!!depositFee && tab === 'withDeposit' && (
            <RowData>
              <div className="dataLabel">Move-In Deposit</div>
              <div>{toMoneyString(depositFee)}</div>
            </RowData>
          )}
          {!!applicationFee && (
            <RowData>
              <div className="dataLabel">Application fee</div>
              <div>{toMoneyString(applicationFee)}</div>
            </RowData>
          )}
          {moveInCharges.map((lc, ix) => {
            return (
              <RowData key={ix}>
                <div className="dataLabel">{lc.title}</div> <div>{toMoneyString(lc.amount)}</div>
              </RowData>
            );
          })}
        </Row>
        <Row>
          <RowData>
            <div className="dataLabel">
              <strong>EST. MOVE-IN COSTS</strong>
            </div>
            <div>
              <strong>{toMoneyString(totalMoveIn)}</strong>
            </div>
          </RowData>
        </Row>
        <Row>
          <p>
            Pricing is subject to change. All calculations are estimates and provided for
            informational purposes only. Actual costs may include additional mandatory or optional
            fees. A complete breakdown of fees will be provided upon approval.
          </p>
        </Row>
      </Wrapper>
    </Section>
  );
};

const MultiFeeWrapper = styled.div`
  font-size: 14px;
  .feeTableHeader {
    font-weight: bold;
  }
  .feeTableRow {
    &:hover {
      background-color: #eee;
    }
    display: flex;
    padding: 5px 10px;
    > div {
      flex: 1;
    }
  }
`;

const RowWrapper = styled.div`
  &:nth-child(even) {
    background: #fafafa;
  }
  &.expanded {
    background-color: #f5f5f5;
    border: 1px solid #00a1ff;
    .feeTableRow {
      background-color: #eee;
    }
    .fa-chevron-right {
      transform: rotate(90deg);
    }
  }
  .feeWrapper {
    border: none;
  }
`;

const FeeTableRow = () => {
  const [expanded, setExpanded] = React.useState();
  return (
    <RowWrapper className={expanded ? 'expanded' : ''}>
      <div className="feeTableRow" onClick={() => setExpanded(!expanded)}>
        <Button>
          <Icon name="fa-light fa-chevron-right" />
        </Button>
        <div>22</div>
        <div>$2500</div>
        <div>1300</div>
        <div>Now</div>
      </div>
      {expanded && <FeeDetails />}
    </RowWrapper>
  );
};

export const MultiUnitFee = ({ location }) => {
  return (
    <MultiFeeWrapper>
      <div className="feeTableRow feeTableHeader">
        <div>Unit</div>
        <div>Monthly Rent</div>
        <div>SF</div>
        <div>Availability</div>
      </div>
      <FeeTableRow />
      <FeeTableRow />
      <FeeTableRow />
      <FeeTableRow />
      <FeeTableRow />
      <FeeTableRow />
    </MultiFeeWrapper>
  );
};

export default FeeDetails;
