import React from 'react';
import styled from 'styled-components';

import { APIProvider, Map } from '@vis.gl/react-google-maps';
import Section from '../section';
import AdvMarker from './marker';
import InfoBox from './info-box';

const API_KEY = 'AIzaSyDwNJevslox8yiM06faMhmlBhC9_jLpjq4';

const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
`;

const isValidLat = (v) => v && Math.abs(v) <= 90;
const isValidLong = (v) => v && Math.abs(v) <= 180;
const parseLatLng = (address) => {
  const lat = address?.geopoint?.[1];
  const lng = address?.geopoint?.[0];
  return !isValidLat(lat) || !isValidLong(lng) ? undefined : { lat, lng };
};

const VisMap = ({ position, title, pointsOfInterest }) => {
  return (
    <APIProvider apiKey={API_KEY}>
      <Map
        mapId="e92aff408f14a9d"
        defaultCenter={position}
        defaultZoom={13}
        // center={currentCenter?.center}
        // zoom={currentCenter?.zoom}
        // onCenterChanged={handleCenterChanged}
        // onZoomChanged={handleCenterChanged}
        // onCameraChanged={handleCameraChange}
        style={{ width: '100%', height: '100%' }}
        gestureHandling="greedy">
        {pointsOfInterest.map((poi) => (
          <AdvMarker key={poi._id} {...poi} />
        ))}
        <AdvMarker type="primary" position={position} title={title} />
      </Map>
    </APIProvider>
  );
};

export default ({ location, nearbyLocations, nearbySchools }) => {
  const [preview, setPreview] = React.useState();
  const parsedLatLng = parseLatLng(location?.address);

  const { pointsOfInterest } = React.useMemo(() => {
    return {
      pointsOfInterest: []
        .concat(
          nearbyLocations?.map((nl) => ({
            _id: nl._id,
            type: 'rental',
            position: parseLatLng(nl?.address),
            title: nl?.displayInfo?.title,
            details: nl?.displayInfo?.address,
            heroImage: nl?.displayInfo?.heroImage,
            extraDetails: [
              { label: 'Rent', value: nl?.displayInfo?.formattedPrice },
              { label: 'Bed Count', value: nl.displayInfo?.bedCount },
              { label: 'Bath Count', value: nl.displayInfo?.bathCount },
              {
                label: 'Square Feet',
                value: nl.displayInfo?.squareFeet ? `${nl.displayInfo?.squareFeet} sqft` : undefined
              }
            ].filter((z) => z.value),
            onClick: setPreview
          }))
        )
        .concat(
          nearbySchools?.map((nl) => {
            const levels = nl.level?.split(',') || [];
            const levelRange =
              levels?.length > 1 ? [levels[0], levels[levels.length - 1]].join('-') : levels[0];

            return {
              _id: `scl-${nl['universal-id']}`,
              type: 'school',
              position: { lat: nl.lat, lng: nl.lon },
              title: nl?.name,
              details: nl?.['school-summary'],
              overviewUrl: nl?.['overview-url'],
              extraDetails: [
                {
                  label: 'Distance',
                  value: nl.distance ? `${Number(nl.distance?.toFixed(2))}ml` : ''
                },
                { label: 'Grades', value: levelRange }
              ].filter((z) => z.value),
              onClick: setPreview
            };
          })
        )
        .filter((z) => z)
    };
  }, [location?._id, nearbyLocations, nearbySchools]);

  if (!parsedLatLng) return <></>;

  return (
    <Section label="LOCATION" id="location">
      <Wrapper>
        <VisMap
          position={parsedLatLng}
          title={location?.displayInfo?.title}
          pointsOfInterest={pointsOfInterest}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwNJevslox8yiM06faMhmlBhC9_jLpjq4&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
        {preview && (
          <InfoBox
            orgId={location.companyId}
            pointOfInterest={preview}
            onClose={() => setPreview(undefined)}
            //onClick={() => setPreviewId(preview._id)}
          />
        )}
      </Wrapper>
    </Section>
  );
};
