import _ from 'lodash';

const fieldMap = {
  beds: ['bedMin', 'bedMax'],
  baths: ['bathMin', 'bathMax'],
  marketRent: ['rentMin', 'rentMax'],
  totalArea: ['sqftMin', 'sqftMax']
};

const getListingRangeValue = (location, field, formatter) => {
  let formattedVal = (fUid) => (formatter ? formatter(location[fUid]) : location[fUid]);

  const values = (
    (location?.listingsUseRanges
      ? fieldMap[field].map((z) => formattedVal(z)).filter((z) => z)
      : [formattedVal(field)]) || [formattedVal(field)]
  ).reduce((f, c) => {
    if (!c || f.includes(c)) return f;

    return f.concat(c);
  }, []);

  return values.length < 2 ? values[0] : `${values[0]} - ${values[1]}`;
};

export const displayLocationInfo = (location = {}) => {
  const title = location?.marketingName || location?.postingTitle || location?.name || '';
  const image = location?.photo?.[0] || location?.marketingFiles?.[0];
  const photos = _.uniq([image, ...(location?.marketingFiles || [])].filter(Boolean));
  const media = photos.map((p, i) => ({ url: p, type: 'image', heroImage: i === 0 }));

  if (location.videoUrl) media.splice(1, 0, { url: location.videoUrl, type: 'video' });
  if (location.virtualTourUrl) {
    media.splice(1, 0, { url: location.virtualTourUrl, type: 'virtualTour' });
  }

  return {
    ...location,
    displayInfo: {
      title,
      formattedPrice: getListingRangeValue(location, 'marketRent', (v) =>
        v?.amount ? `${(v?.amount / 100).renderPrettyCurrency(false)}` : ''
      ),
      heroImage: image,
      bedCount: getListingRangeValue(location, 'beds'),
      bathCount: getListingRangeValue(location, 'baths'),
      squareFeet: getListingRangeValue(location, 'totalArea'),
      availableDate: location?.availableDate,
      leaseTerms: location?.leaseTerms || location?.leaseTermUnits || location?.leaseTerm,
      photos,
      address: location?.address?.formattedAddress,
      media
    }
  };
};
