import React from 'react';

export default ({ anchors = [], container }) => {
  const [currentAnchor, setAnchor] = React.useState(anchors[0]);

  const getPositions = () => {
    const containerEl = document.getElementById(container);
    const position = window.getComputedStyle(containerEl)?.position;
    const containerOffsetTop = position === 'static' ? containerEl?.offsetTop : 0;
    const positions = [];
    let currentIndex = 0;
    anchors.forEach((anchor) => {
      const el = document.getElementById(anchor);
      if (!el) return;
      const scrollTop = el.offsetTop - containerOffsetTop;
      if (currentIndex > 0) {
        positions[currentIndex - 1].max = scrollTop - 51;
      }
      positions.push({
        id: anchor,
        min: scrollTop - 50,
        max: currentIndex === anchors.length - 1 ? 999999 : undefined
      });
      currentIndex += 1;
    });
    return positions;
  };

  const onScrollEnd = (event) => {
    const positions = getPositions();
    const scrollTop = event.target.scrollTop;
    const position = positions.find((pos) => scrollTop >= pos.min && scrollTop <= pos.max);
    if (position && position.id !== currentAnchor) {
      setAnchor(position.id);
    }
  };

  const handleAnchorChange = (id) => {
    const containerEl = document.getElementById(container);
    const pos = getPositions().find((p) => p.id === id);
    containerEl.scrollTop = pos.min;
    setAnchor(id);
  };

  return { activeTab: currentAnchor, onScrollEnd, setActiveTab: handleAnchorChange };
};
