import { POST } from './utils';

export const GET_DEVICE_CODE = ({ userContext, data }) =>
  POST({
    url: `ttLockDeviceCode/get`,
    data,
    token: userContext?.accessToken?.token
  });

export const CREATE_DEVICE_CODE = ({ userContext, data }) =>
  POST({
    url: `ttLockDeviceCode/create`,
    data,
    token: userContext?.accessToken?.token
  });
