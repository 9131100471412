import React from 'react';
import styled from 'styled-components';

import Icon from 'components/icon';

const LoadingPanel = styled.div`
  position: absolute;
  display: flex;
  z-index: 999;
  background-color: #63636380;
  width: 100%;
  height: 100%;
  > i {
    color: #ffffffeb;
    margin: auto;
  }
`;

export default () => {
  return (
    <LoadingPanel>
      <Icon name="fa-duotone fa-spinner-third fa-spin" size={100} />
    </LoadingPanel>
  );
};
