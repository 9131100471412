import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import Search from './search';
import Property from './property';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  *:not(i) {
    box-sizing: border-box;
  }
`;

export default () => {
  return (
    <Wrapper>
      <Routes>
        <Route path="*" element={<Search />} />
      </Routes>
    </Wrapper>
  );
};
