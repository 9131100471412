import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  &.withLogo {
    padding: 0 20px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const Action = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

const Logo = styled.div`
  width: 30%;
  height: 100%;
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export default ({ title, onBack, logoUrl }) => {
  return (
    <Wrapper className={logoUrl ? 'withLogo' : ''}>
      {onBack && (
        <Action onClick={onBack}>
          <Icon name="fa-solid fa-arrow-left" />
        </Action>
      )}
      {title && <Title>{title}</Title>}
      {logoUrl && <Logo url={logoUrl} />}
    </Wrapper>
  );
};
