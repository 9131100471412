import React from 'react';
import styled from 'styled-components';

import Modal from 'components/modal';

const StyledIFrame = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;

export default ({ url, ...props }) => {
  if (!props.open && !url) return <></>;

  return (
    <Modal {...props}>
      <StyledIFrame border="0" src={url} id="iFrame" width="100%" height="100%" />
    </Modal>
  );
};
