import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';
import Icon from 'components/icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 400;
  width: 420px;
  height: 120px;
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.3);
  }
  .body {
    height: 120px;
    overflow-y: auto;
    flex: 1;
    padding: 10px 10px;
    .info-title {
      cursor: pointer;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .info-details {
      font-size: 13px;
    }
    .extra-details {
      font-size: 13px;
    }
  }
  button {
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;
    top: 5px;
    right: 10px;
  }
  @media (max-width: 768px) {
    bottom: 80px;
    width: 90%;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.3);
  }
`;

const Photo = styled.div`
  width: 120px;
  height: 120px;
  background-color: #eee;
  background-image: url(${({ img }) => img}), url('/assets/location-placeholder2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export default ({ orgId, onClose, pointOfInterest }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (pointOfInterest.type === 'rental' && pointOfInterest?._id) {
      navigate(`/search/${orgId}/${pointOfInterest?._id}`);
      return false;
    }
    if (pointOfInterest.type === 'school' && pointOfInterest?.overviewUrl) {
      window.open(pointOfInterest.overviewUrl, '_blank');
      return false;
    }
  };

  return (
    <Wrapper>
      {pointOfInterest.heroImage && <Photo img={pointOfInterest.heroImage} onClick={handleClick} />}
      <div className="body">
        <div className="info-title" onClick={handleClick}>
          {pointOfInterest.title}
        </div>
        {!!pointOfInterest.details && <div className="info-details">{pointOfInterest.details}</div>}

        {pointOfInterest?.extraDetails?.length > 0 && (
          <div className="extra-details">
            {pointOfInterest?.extraDetails?.map((ed, ix) => {
              return (
                <div key={ix}>
                  {ed.label}: {ed.value}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClose?.();
        }}>
        <Icon name="fa-solid fa-xmark" />
      </Button>
    </Wrapper>
  );
};
