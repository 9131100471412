import { darken, lighten, rgba } from 'polished';

export const primary = '#3D5AFE';
export const primary_transparent = '#5a71f245';
export const primary_tint = '#979fb2';
export const accent = '#271048';
export const borders = '#B0BEC5';
export const shadows = '#D5DCE0';
export const borderLight = '#E6E6E6';
export const borderLight2 = '#EAE9EA';
export const lightBlue = '#4dace9';
export const darkBlue = '#2390e5';
export const softBlue = '#A6B5FB';

export const grayScale = {
  fontDarker: '#5f6368',
  fontRegular: '#80868b',
  backgroundDarker: '#c4c4c4',
  backgroundRegular: '#f4f4f4',
  backgroundLight: '#fafafa'
};

export const nav = {
  primary: '#123851',
  secondary: '#F6F7F9'
};

export const colorProgression = {
  good: '#0dd425',
  ok: '#0d53d4',
  meh: '#c8d40d',
  bad: '#d48e0d',
  terrible: '#d40d0d'
};

export const highlights = {
  primary: '#FEF8E4',
  secondary: '#FAFDFF',
  third: '#00e5ff',
  alternative: '#E7EEF3',
  warning: '#ffe79d',
  selected: '#ede6fb',
  bulkSelected: '#dcc9ff'
};

export const background = {
  primary: '#f0f3f6',
  secondary: '#FFFFFF',
  alternative: '#F6F7F9',
  third: '#FAFAFA',
  softGray: '#e8ecf2',
  darkGray: '#A9B4BB',
  bitDarkGray: lighten(0.2, '#A9B4BB'),
  accent: darken(0.1, '#f0f3f6'),
  highlight2: '#eff7fc',
  swipeDelete: '#fedcdc',
  swipeClose: '#ddfedc',
  swipeFlag: '#feefdc',
  swipeMore: '#eaeaea',
  lightGray: '#f8f8f8',
  red: '#fe4848'
};

export const font = {
  primary: '#152934',
  secondary: '#FFFFFF',
  alternative: '#9CA7B1',
  inactive: '#71828f',
  low: rgba(0, 0, 0, 0.7),
  extraLow: rgba(0, 0, 0, 0.4),
  hyperlink: '#00ADEE',
  swipeDelete: '#bf7070',
  swipeClose: '#8cb38a',
  swipeFlag: '#efac56',
  swipeMore: '#a8aeb5'
};

export const discussions = {
  open: '#FFCD37',
  waiting: '#D0021B',
  replied: '#0FB216',
  noStatus: '#C3C3C3'
};

/* ALERTS */
export const alertLow = '#f68c8c';
export const alert = '#FC6363'; // FIXME: alert is a reserved word!
export const warning = '#FFC71D';
export const success = '#78C37B';
export const low = '#AAB4BC';
export const accentGrey = darken(0.4, font.alternative);
/* ALERTS */
