import React from 'react';
import styled from 'styled-components';

import Icon from 'components/icon';
import Address from 'components/address';

const Wrapper = styled.div`
  position: relative;
  height: 45px;
  flex: 1;
  max-width: 600px;
  input {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .searchIcon {
    position: absolute;
    left: 15px;
    top: calc(50% - 8px);
  }
  .fa-xmark {
    position: absolute;
    right: 10px;
    top: calc(50% - 7px);
    cursor: pointer;
  }
`;

export default ({ value, onChange, autoFocus, icon, onIconClick }) => {
  return (
    <Wrapper>
      <Address
        autoFocus={autoFocus}
        value={value}
        placeholder="Address, neighborhood, city or zip"
        onChange={onChange}
      />
      <Icon
        onClick={onIconClick}
        className="searchIcon"
        name={icon || 'fa-duotone fa-solid fa-magnifying-glass'}
      />
      {/* {value && (
        <Icon
          name="fa-light fa-xmark"
          onClick={() => {
            onChange(null);
            inputRef.current.focus();
          }}
        />
      )} */}
    </Wrapper>
  );
};
