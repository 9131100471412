import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { ellipsis } from 'polished';
import Icon from './icon';

const PortalWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  top: 0;
  left: 0;
`;

const Backdrop = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(0 0 0 / 12%) 0px 15px 30px 0px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  z-index: 2;
  margin-top: 10px;
  border: 1px solid #e6e6e6;
`;

const Popover = ({ children, autoWidth, placeholder }) => {
  const [isOpen, setIsOpen] = React.useState();
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const closeMenu = () => setIsOpen(false);

  return (
    <>
      {placeholder?.({
        ref: setReferenceElement,
        open: isOpen,
        onClick: () => setIsOpen(!isOpen)
      })}
      {isOpen &&
        ReactDOM.createPortal(
          <PortalWrapper>
            <Backdrop onClick={closeMenu} />
            <Content
              ref={setPopperElement}
              style={styles.popper}
              className={autoWidth ? 'width-auto' : ''}
              {...attributes.popper}>
              {children({
                closeMenu
              })}
            </Content>
          </PortalWrapper>,
          document.body
        )}
    </>
  );
};

export default Popover;
