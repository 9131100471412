import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { DOG_BREEDS } from 'data/breeds';
import { randomId } from 'dal/utils';
import Modal from 'components/modal';
import FormGroup from 'components/form-group';
import Input from 'components/input';
import Select from 'components/select';
import Checkbox from 'components/checkbox';
import Button from 'components/button';
import Icon from 'components/icon';
import Text from 'components/text';

import MultiUpload from '../multi-upload';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 56px;
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 1.13em;
  text-transform: capitalize;
`;

const Body = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  justify-content: flex-end;
  gap: 15px;
  button {
    padding: 7px 12px !important;
  }
`;

const MAX_FILE_COUNT = 7;
const petSchema = (t) => {
  return yup.object({
    type: yup.string().required(`${t('petsStep.validate.chooseType')}`),
    uuid: yup.string(),
    name: yup.string().required(`${t('petsStep.validate.name')}`),
    breed: yup.string().required(`${t('petsStep.validate.bred')}`),
    weight: yup
      .string()
      .matches(/^\d*\.?\d*$/, `${t('petsStep.validate.provide')}`)
      .required(`${t('petsStep.validate.wight')}`),
    age: yup
      .string()
      .matches(/^\d*\.?\d*$/, `${t('petsStep.validate.provideAge')}`)
      .required(`${t('petsStep.validate.age')}`),
    sex: yup.string().required(`${t('petsStep.validate.sex')}`),
    comments: yup.string(`${t('petsStep.validate.comments')}`),
    serviceAnimal: yup.boolean()
  });
};

const defaultPet = {
  type: '',
  name: '',
  breed: '',
  sex: '',
  age: '',
  weight: '',
  comments: '',
  serviceAnimal: false
};

const Pet = ({ onClose, pet, onSave, t, application }) => {
  const petId = React.useMemo(() => pet.uuid || randomId(), [pet.uuid]);
  const [petFiles, setPetFiles] = React.useState([]);
  const [petPhotos, setPetPhotos] = React.useState([]);

  const [showBreedInput, setShowBreedInput] = React.useState(
    () => pet.type === 'dog' && pet.breed && DOG_BREEDS.some((b) => b.value !== pet.breed)
  );
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: { uuid: petId, ...defaultPet, ...pet },
      validationSchema: petSchema(t),
      validate: (submittedValues) => {
        const errors = {};
        if (submittedValues?.serviceAnimal && !petFiles?.length) {
          errors.files = t('petsStep.validate.files');
        }
        return errors;
      },
      onSubmit: (values) => {
        onSave({ ...values });
        onClose();
      }
    });

  const showLabelType = (type, t) => {
    switch (type) {
      case 'cat':
        return `${t('cat')}`;
      case 'dog':
        return `${t('dog')}`;
      case 'other':
        return `${t('otherAnimal')}`;
    }
    return type;
  };

  return (
    <Wrapper>
      <Header>
        {pet.uuid
          ? `${t('edit')}  ${showLabelType(pet.type, t)}`
          : `${t('ADD')} ${showLabelType(pet.type, t)}`}
      </Header>
      <Body>
        <FormGroup label={t('form.animalName')} errorText={touched.name && errors.name}>
          <Input name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
        </FormGroup>
        <FormGroup label={t('form.breed')} errorText={touched.breed && errors.breed}>
          {pet.type === 'dog' && (
            <Select
              name="breed"
              value={showBreedInput ? 'other' : values.breed}
              onChange={(event) => {
                if (event.target.value === 'other') {
                  setFieldValue('breed', '');
                  setShowBreedInput(true);
                } else {
                  setShowBreedInput(false);
                  handleChange(event);
                }
              }}>
              <option value="">{t('buttonsActions.slectBreed')}</option>
              {DOG_BREEDS.map((bo) => (
                <option key={bo.value} value={bo.value}>
                  {bo.label}
                </option>
              ))}
            </Select>
          )}
          {(pet.type !== 'dog' || showBreedInput) && (
            <>
              {showBreedInput && <br />}
              <Input
                name="breed"
                value={values.breed}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </>
          )}
        </FormGroup>

        <FormGroup label={t('form.sex')} errorText={touched.sex && errors.sex}>
          <Select
            name="sex"
            value={values.sex}
            onChange={(event) => {
              if (event.target.value === 'other') {
                setFieldValue('breed', '');
                setShowBreedInput(true);
              } else {
                setShowBreedInput(false);
                handleChange(event);
              }
            }}>
            <option value="">{t('form.selectSex')}</option>
            <option value="male">{t('form.sexMale')}</option>
            <option value="female">{t('form.sexFemale')}</option>
            <option value="n/a">{t('form.sexNA')}</option>
          </Select>
        </FormGroup>
        <FormGroup label={t('weight')} errorText={touched.weight && errors.weight}>
          <Input name="weight" value={values.weight} onChange={handleChange} onBlur={handleBlur} />
        </FormGroup>
        <FormGroup label={t('age')} errorText={touched.age && errors.age}>
          <Input name="age" value={values.age} onChange={handleChange} onBlur={handleBlur} />
        </FormGroup>
        <FormGroup label={t('form.animalPhoto')} errorText={errors.photos}>
          <MultiUpload
            onChange={setPetPhotos}
            application={application}
            field="animalPhoto"
            referenceId={petId}
            maxFiles={MAX_FILE_COUNT}
            fileTypes={['.jpg', '.jpeg', '.png']}
          />
        </FormGroup>
        <FormGroup label={t('form.comments')} errorText={errors.comments}>
          <Text
            name="comments"
            value={values.comments}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={values.serviceAnimal}
            onChange={() => setFieldValue('serviceAnimal', !values.serviceAnimal)}
            label={t('petEdition.serviceAnimal')}
          />
        </FormGroup>
        <FormGroup>
          <div>
            <Icon color="orange" name="fa-duotone fa-triangle-exclamation" />
            {''}
            <Trans i18nKey="petEdition.notice">
              <strong>NOTICE</strong>
            </Trans>
          </div>
          {t('petEdition.noticeDescrip')}
        </FormGroup>
        {values.serviceAnimal && (
          <FormGroup label={t('petEdition.serviceAnimalFiles')} errorText={errors.files}>
            <MultiUpload
              onChange={setPetFiles}
              application={application}
              field="animal"
              referenceId={petId}
              minFiles={1}
              maxFiles={MAX_FILE_COUNT}
              fileTypes={['.pdf', '.jpg', '.jpeg', '.png']}
            />
          </FormGroup>
        )}
      </Body>
      <Actions>
        <Button variant="text" onClick={onClose}>
          {t('buttonsActions.cancel')}
        </Button>
        <Button variant="primary" radius="25px" onClick={handleSubmit}>
          {t('buttonsActions.save')}
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default ({ open, onClose, ...props }) => (
  <Modal closeOnOutsideClick={false} open={open} onClose={onClose} width="600px" height="70%">
    <Pet onClose={onClose} {...props} />
  </Modal>
);
