import React from 'react';
import styled from 'styled-components';
import SearchInput from './search-input';
import Button from 'components/button';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  z-index: 999;
`;

const Search = styled(Button)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  color: #fff;
  background-color: #00a1ff;
  padding: 12px 16px;
  width: 90%;
`;

export default ({ onChange, onClose }) => {
  const [val, setVal] = React.useState();
  return (
    <Wrapper>
      <SearchInput
        onIconClick={onClose}
        icon="fa-solid fa-arrow-left"
        value={val}
        onChange={(text) => setVal(text)}
        autoFocus
      />
      <Search onClick={onClose}>Search</Search>
    </Wrapper>
  );
};
