import React from 'react';
import styled from 'styled-components';
import ImagePreviewer from 'components/image-previewer';
import Button from 'components/button';
import Icon from 'components/icon';
import Image from 'components/image';

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  border-right: 1px solid #ddd;
  &.gallerySlider {
    border: none;
    overflow: hidden;
    height: auto;
    padding: 0px;
    .mediaContainer {
      border-radius: 0px;
      height: 400px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, 1fr);
`;

const Slider = styled.div`
  height: 400px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  button {
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.18), 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
    color: #000;
    &:first-child {
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child {
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .imageCount {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 3px 12px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    pointer-events: none;
  }
`;

const MediaWrapper = styled.div`
  height: 250px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  .smartImage {
    width: 100%;
    height: 100%;
  }
  &.mediaHidden {
    display: none;
  }
  &.media-virtualTour,
  &.previewLarge {
    grid-column: 1 / -1;
    height: 700px;
  }
  &:first-child {
    grid-column: 1 / -1;
    height: 400px;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const parseVideo = (url = '') => {
  const position = url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );
  let type;
  if (position?.[3]?.indexOf('youtu') > -1) {
    type = 'youtube';
  } else if (position?.[3]?.indexOf('vimeo') > -1) {
    type = 'vimeo';
  }

  return {
    type: type,
    id: position?.[6]
  };
};

const Embed = ({ url }) => {
  const videoObj = parseVideo(url);

  let player = url;
  if (videoObj.type == 'youtube') {
    player = `https://www.youtube.com/embed/${videoObj.id}`;
  } else if (videoObj.type == 'vimeo') {
    player = `https://player.vimeo.com/video/${videoObj.id}`;
  }
  return <iframe src={player} frameBorder="0" allowFullScreen />;
};

const MediaRender = ({ media = {}, onClick, hidden, optimizeRender = true }) => {
  if (hidden && optimizeRender) return null;
  const previewLarge = ['zillow.com/view-imx', 'jamiefarleyphotography'].some((domain) =>
    media?.url?.includes(domain)
  );
  return (
    <MediaWrapper
      className={`media-${media.type} mediaContainer ${hidden ? 'mediaHidden' : ''} ${
        previewLarge ? 'previewLarge' : ''
      }`}>
      {media.type === 'image' && (
        <Image onClick={onClick} url={media.url} fallback="/assets/location-placeholder2.png" />
      )}
      {media.type === 'virtualTour' && <iframe src={media.url} frameBorder="0" allowFullScreen />}
      {media.type === 'video' && <Embed url={media.url} />}
    </MediaWrapper>
  );
};

export default ({ isSlider, location: { displayInfo: { media = [] } = {} } = {} }) => {
  const [showPreviewer, setShowPreviewer] = React.useState();
  const [currentPos, setCurrentPos] = React.useState(0);

  const handleNextPrev = (dir) => {
    const nextPos = dir === 'next' ? currentPos + 1 : currentPos - 1;
    setCurrentPos(nextPos === media.length ? 0 : nextPos < 0 ? media.length - 1 : nextPos);
  };

  return (
    <Wrapper className={isSlider ? 'gallerySlider' : ''}>
      {isSlider && (
        <Slider onClick={() => setShowPreviewer(true)}>
          {media.map((media, i) => (
            <MediaRender
              key={i}
              media={media}
              onClick={() => setShowPreviewer(true)}
              hidden={currentPos !== i}
            />
          ))}
          {media.length > 1 && (
            <div>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleNextPrev('prev');
                }}>
                <Icon name="fa-solid fa-arrow-left" />
              </Button>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleNextPrev('next');
                }}>
                <Icon name="fa-solid fa-arrow-right" />
              </Button>
            </div>
          )}
          <div className="imageCount">
            {currentPos + 1}/{media.length}
          </div>
        </Slider>
      )}
      {!isSlider && (
        <Grid>
          {media.map((media, i) => (
            <MediaRender key={i} media={media} onClick={() => setShowPreviewer(true)} />
          ))}
        </Grid>
      )}
      <ImagePreviewer
        open={showPreviewer}
        urls={media.filter((m) => m.type === 'image').map((photo) => photo.url)}
        onClose={() => setShowPreviewer(false)}
      />
    </Wrapper>
  );
};
