import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';

const Wrapper = styled.div`
  display: inline-flex;
  border-radius: 4px;
  gap: 5px;
  flex-wrap: wrap;
`;

const Option = styled(Button)`
  height: 40px;
  padding: 0 16px;
  min-width: 42px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: var(--color-font-primary);

  &:hover {
    background-color: #f5f5f5;
  }
  &.selected {
    background-color: #e5f6ff;
    outline: 1px solid #00a1ff;
  }
`;

export default ({ options = [], onChange, value = '' }) => (
  <div>
    <Wrapper>
      {options.map((option, i) => (
        <Option
          key={i}
          className={value === option.value ? 'selected' : ''}
          onClick={() => onChange(option)}>
          {option.label}
        </Option>
      ))}
    </Wrapper>
  </div>
);
