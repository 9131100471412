import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { primary, primary_transparent } from 'styles/colors';
import Menu from 'components/menu';
import Button from 'components/button';
import Icon from 'components/icon';

import LoadingOverlay from '../loading-overlay';
import Item from './item';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Grid = styled.div`
  flex: 1;
  gap: 15px;
  overflow-y: auto;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr 1fr;
  width: 620px;
  padding: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 580px) {
    grid-template-columns: 1fr;
  }
`;

const Results = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 10px;
`;
const Pager = styled.div`
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  > button {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }
  .selected-page {
    border: 2px solid ${primary};
    border-radius: 50%;
    background-color: ${primary_transparent};
  }
`;

const SORT_OPTIONS = [
  { label: 'Availability', id: 'availability' },
  { label: 'Price', id: 'price' }
];

export default ({
  locations = [],
  orgId,
  locationCount,
  setPageNumber,
  pageNumber,
  pageLength,
  loading,
  sort,
  setSort,
  activeMarkerId
}) => {
  const navigate = useNavigate();

  const { pageCount, displayedPageButtons } = React.useMemo(() => {
    const pageCount = Math.ceil(locationCount / pageLength);

    const pageBuffer = 3;
    let minPageMid = 0 + pageBuffer;
    minPageMid = minPageMid > pageCount ? pageCount : minPageMid;
    let maxPageMid = pageCount - pageBuffer;
    maxPageMid = maxPageMid < minPageMid ? minPageMid : maxPageMid;

    let curMid = minPageMid;

    if (pageNumber > minPageMid) {
      curMid = pageNumber;
    }
    if (curMid > maxPageMid) {
      curMid = maxPageMid;
    }

    let dpb = [];
    for (let pc = curMid - pageBuffer; pc <= curMid + pageBuffer; pc++) {
      if (pc < pageCount && pc >= 0) dpb.push(pc);
    }

    return { pageCount, displayedPageButtons: dpb };
  }, [pageLength, locationCount, pageNumber]);

  return (
    <Wrapper>
      {loading && <LoadingOverlay />}
      <Results>
        <span>
          <strong>{locationCount} Results</strong>
        </span>
        <Menu
          options={SORT_OPTIONS}
          onItemClick={(e) => {
            setSort({ col: e.id, dir: 1 });
          }}>
          {(provided) => (
            <span style={{ cursor: 'pointer' }} {...provided}>
              Sort By:{' '}
              <strong>
                {(SORT_OPTIONS.find((o) => o.id === sort.col) || SORT_OPTIONS[0]).label}
              </strong>
            </span>
          )}
        </Menu>
      </Results>
      <Grid>
        {locations.map((location, i) => (
          <Item
            isActive={activeMarkerId === location._id}
            key={location._id || i}
            location={location}
            onClick={() => navigate(`/search/${orgId}/${location._id}`)}
          />
        ))}
      </Grid>
      {pageCount > 1 && (
        <Pager>
          <Button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber < 1}>
            <Icon name="fa-solid fa-chevron-left" />
          </Button>
          {displayedPageButtons.map((pNum) => {
            return (
              <Button
                key={pNum}
                className={pNum === pageNumber ? 'selected-page' : ''}
                onClick={() => setPageNumber(pNum)}>
                {pNum + 1}
              </Button>
            );
          })}
          <Button
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber + 1 >= pageCount}>
            <Icon name="fa-solid fa-chevron-right" />
          </Button>
        </Pager>
      )}
    </Wrapper>
  );
};
