import React from 'react';
import styled, { css } from 'styled-components';

import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  background: #eff8fd;
  height: 100%;
  > div {
    height: 100%;
    overflow: hidden;
    position: relative;
    flex: 1;
    width: 100%;
    gap: 10px;
    max-width: ${({ maxWidth }) => maxWidth};
    display: flex;
  }

  ${({ isMobile }) =>
    !isMobile
      ? css`
          display: flex;
          overflow: hidden;
          align-items: baseline;
          justify-content: center;
          > div {
            padding: 20px;
            overflow: hidden;
          }
        `
      : css`
          overflow-y: auto;
          > div {
            overflow-y: auto;
            display: block;
            flex-flow: column;
          }
        `}
`;

export default ({ leftPane, rightPane, maxWidth = '1375px', mobileMaxWidth = 800 }) => {
  const isMobile = useMediaQuery({ maxWidth: mobileMaxWidth });

  return (
    <Wrapper isMobile={isMobile} maxWidth={maxWidth}>
      <div>
        {leftPane ? leftPane : undefined}
        {rightPane ? rightPane : undefined}
      </div>
    </Wrapper>
  );
};
