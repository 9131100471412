import React from 'react';
import { useAPIData } from 'dal/useAPIData';
import { displayLocationInfo } from 'helpers/location';

import useNearbyLocations from './useNearbyLocations';
import useNearbySchools from './useNearbySchools';

export const useProperty = ({ locationId, orgId }) => {
  const [location, inProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_LISTING',
    !locationId ? undefined : { locationId },
    { reFetchWhen: [locationId], disableEmptyArgs: true }
  );

  const nearbyLocations = useNearbyLocations({ orgId, locationId, address: location?.address });
  const nearbySchools = useNearbySchools({ locationId });

  return {
    failed: failedFetch,
    location: displayLocationInfo(location),
    loading: inProgress || !location,
    nearbyLocations: nearbyLocations?.locations,
    nearbySchools: nearbySchools?.schools
  };
};
