import styled from 'styled-components';

export const RowGroup = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  gap: 10px;
  margin-bottom: ${({ spacing = 25 }) => `${spacing}px`};
  .Aptly__Form-group {
    margin-bottom: 0px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: ${({ spacing = 25 }) => `${spacing}px`};
  }
`;

const FieldGroupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ spacing = 25 }) => `${spacing}px`};
  &:last-child {
    margin-bottom: 0px;
  }
  .group-label {
    display: flex;
    align-items: center;
    font-size: 0.94em;
    margin-bottom: 5px;
  }
  .tooltip {
    cursor: help;
  }
  @media (max-width: 740px) {
    width: 100%;
  }
`;

const ErrorText = styled.span`
  font-size: 0.81em;
  margin-top: 5px;
  color: red;
`;
const SubText = styled.span`
  font-size: 0.81em;
  margin-top: 5px;
  color: #6a6a6a;
`;

const FormGroup = ({
  label,
  errorText,
  children,
  className = '',
  subText = '',
  spacing,
  ...props
}) => {
  return (
    <FieldGroupWrapper className={`Aptly__Form-group ${className}`} spacing={spacing} {...props}>
      {label && <label className={`group-label ${props.title ? 'tooltip' : ''}`}>{label}</label>}
      {children}
      {subText && <SubText>{subText}</SubText>}
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </FieldGroupWrapper>
  );
};

export default FormGroup;
