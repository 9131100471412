import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/button';
import Icon from 'components/icon';
import Menu from 'components/menu';

import ContactUsModal from './contact-us';

const Wrapper = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
`;

const Action = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  color: var(--color-font-primary);
  &:hover {
    background-color: #f5f5f5;
  }
`;

const RightActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

const PrimaryAction = styled(Button)`
  background-color: #00a1ff;
  padding: 8px 16px;
  color: #fff;
  border-radius: 4px;
  &:hover {
    background-color: ${darken(0.09, '#00a1ff')};
  }
  &.secondaryBtn {
    background-color: #fff;
    color: #444;
    &:hover {
      color: #00a1ff;
    }
  }
`;
const HiddenLink = styled.a`
  display: none;
`;

export default ({ location, onClose, onNextBack, showNavButtons }) => {
  const linkRef = React.useRef();
  const [contactOpen, setContactOpen] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const handleShare = () => {
    linkRef?.current?.click?.();
  };
  const handleContactUs = () => setContactOpen(true);
  const handleApply = () => {
    // This goes to app create, it will bounce and redirect as needed
    window.open(
      `/${location.companyInfo.id}/applicant/app/applications/form/create/?cId=${location._id}`,
      '_blank'
    );
  };
  const handleShowing = () => {
    // This goes to app create, it will bounce and redirect as needed
    window.open(
      `/${location.companyInfo.id}/showing/app/showing/create/?cId=${location._id}`,
      '_blank'
    );
  };

  const { options, contactUsEnabled, applyEnabled, showingEnabled } = React.useMemo(() => {
    const contactUsEnabled = !!location?.aptlyListings?.contactFormUrl;
    const showingEnabled = !!location?.aptlyShowings?.active;
    const applyEnabled = !!location?.aptlyScreening?.active;
    const options = [
      {
        label: 'Share',
        icon: 'fa-duotone fa-solid fa-arrow-up-from-bracket',
        onClick: handleShare
      }
    ];

    if (contactUsEnabled) {
      options.push({
        label: 'Contact Us',
        icon: 'fa-solid fa-circle-info',
        onClick: handleContactUs
      });
    }
    if (showingEnabled) {
      options.push({
        label: 'Request Tour',
        icon: 'fa-solid fa-key',
        onClick: handleShowing
      });
    }

    return { applyEnabled, contactUsEnabled, showingEnabled, handleContactUs, options };
  }, [location]);

  return (
    <Wrapper>
      {contactOpen && (
        <ContactUsModal
          open={true}
          width="1200px"
          height="98%"
          maxHeight="100%"
          url={location?.aptlyListings?.contactFormUrl}
          onClose={() => setContactOpen(false)}
        />
      )}
      <Action onClick={onClose}>
        <Icon name="fa-solid fa-xmark" />
      </Action>
      {showNavButtons && (
        <>
          <Action marginLeft={20} onClick={() => onNextBack('back')}>
            <Icon name="fa-solid fa-arrow-left" />
          </Action>
          <Action onClick={() => onNextBack('next')}>
            <Icon name="fa-solid fa-arrow-right" />
          </Action>
        </>
      )}
      <RightActions>
        {!isMobile && contactUsEnabled && (
          <PrimaryAction className="secondaryBtn" onClick={handleContactUs}>
            Contact Us
          </PrimaryAction>
        )}
        {!isMobile && showingEnabled && (
          <PrimaryAction className="secondaryBtn" onClick={handleShowing}>
            Request Tour
          </PrimaryAction>
        )}
        {applyEnabled && <PrimaryAction onClick={handleApply}>Apply</PrimaryAction>}
        {!isMobile && (
          <Action onClick={handleShare}>
            <Icon name="fa-duotone fa-solid fa-arrow-up-from-bracket" />
          </Action>
        )}
        {isMobile && (
          <Menu options={options}>
            {(provided) => (
              <Action {...provided}>
                <Icon name="fa-solid fa-ellipsis-vertical" />
              </Action>
            )}
          </Menu>
        )}
        <HiddenLink
          ref={linkRef}
          target="_blank"
          rel="noopener"
          noreferrer={true}
          href={`mailto:?subject=${encodeURIComponent(
            `Check out this property I've found online`
          )}&body=${encodeURIComponent(
            `${location.displayInfo?.title || ''}: ${window.location.href}`
          )}`}>
          &nbsp;
        </HiddenLink>
      </RightActions>
    </Wrapper>
  );
};
