import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';

const Wrapper = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: center;
`;

const InputWrapper = styled.div`
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  flex: 1;
  overflow: hidden;
  position: relative;
  .controlWrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  .rangeLabel {
    color: #6a6a6a;
    margin-bottom: 5px;
    font-size: 13px;
  }
  input {
    border: none;
    outline: none;
    padding-left: 10px;
    width: 100%;
    height: 100%;
  }
  .fa-dollar-sign {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
  }
`;

export default ({ value, onChange, autoFocus }) => {
  const [range, setRange] = React.useState({ min: value?.min || '', max: value?.max || '' });
  const firstRender = React.useRef(false);

  const handleChange = (event) => {
    const val = event.target.value.replace(/[^0-9]/g, '');
    const key = event.target.name;
    setRange((prev) => ({ ...prev, [key]: val ? Number(val) : '' }));
  };

  React.useEffect(() => {
    firstRender.current = true;
    if (firstRender.current) {
      const isValidRange = range.min && range.max ? range.min < range.max : range.min || range.max;
      isValidRange && onChange?.(range);
    }
  }, [range]);

  return (
    <Wrapper>
      <InputWrapper className="bar">
        <div className="rangeLabel">Min</div>
        <div className="controlWrapper">
          <input
            type="number"
            name="min"
            value={range.min}
            onChange={handleChange}
            placeholder="Not set"
            autoFocus={autoFocus}
          />
          <Icon name="fa-solid fa-dollar-sign" />
        </div>
      </InputWrapper>
      <span>to</span>
      <InputWrapper>
        <div className="rangeLabel">Max</div>
        <div className="controlWrapper">
          <input
            type="number"
            name="max"
            value={range.max}
            onChange={handleChange}
            placeholder="Not set"
          />
          <Icon name="fa-solid fa-dollar-sign" />
        </div>
      </InputWrapper>
    </Wrapper>
  );
};
