import React from 'react';
import styled from 'styled-components';
import Shape from 'components/skeleton-shape';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Gallery = styled.div`
  margin: 10px;
  .photos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Details = styled.div`
  margin: 10px;
  padding: 20px 0px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export default () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <Wrapper>
      <Gallery>
        <Shape height="300px" borderRadius="10px" />
        <div className="photos">
          <Shape height="250px" borderRadius="10px" />
          <Shape height="250px" borderRadius="10px" />
        </div>
      </Gallery>
      <Details>
        {isMobile && <Shape height="300px" width="100%" marginBottom="10px" borderRadius="10px" />}
        <Shape height="20px" width="70%" marginBottom="10px" borderRadius="10px" />
        <Shape height="20px" width="50%" marginBottom="30px" borderRadius="10px" />
        {!isMobile && <Shape height="300px" width="100%" marginBottom="10px" borderRadius="10px" />}
      </Details>
    </Wrapper>
  );
};
