import React from 'react';
import styled from 'styled-components';
import InfoBox from './info-box';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import ClusteredMarkers from './clustered-markers';
const API_KEY = 'AIzaSyDwNJevslox8yiM06faMhmlBhC9_jLpjq4';

const Wrapper = styled.div`
  flex: 1;
  background: #ddd;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;

const isValidLat = (v) => v && Math.abs(v) <= 90;
const isValidLong = (v) => v && Math.abs(v) <= 180;

const defaultVal = [-98.583333, 39.833333];
const getDefaultLocation = (locations) => {
  return {
    lng: locations?.[0]?.address?.geopoint?.[0] || defaultVal[0], //CODEBUG in aptly have these reversed :(
    lat: locations?.[0]?.address?.geopoint?.[1] || defaultVal[1], //CODEBUG in aptly have these reversed :(
    defaultZoom: locations?.[0]?.address?.geopoint?.[1] ? 13 : 4
  };
};

const VisMap = ({ locations = [], activeMarker, onMarkerClick, center, zoom, onCameraChanged }) => {
  const [map, setMap] = React.useState(null);
  const { lng, lat, defaultZoom } = getDefaultLocation(locations);
  const [currentCenter, setCurrentCenter] = React.useState({
    center: lat && lng ? { lat, lng } : undefined,
    zoom: defaultZoom
  });
  // const [coordinates, setCoordinates] = React.useState(lat && lng ? { lat, lng } : undefined);
  // const [currentZoom, setCurrentZoom] = React.useState(defaultZoom);

  // reset this so we have a clean map pan
  const handleCenterChanged = () => setCurrentCenter({});
  React.useEffect(() => {
    const updatedVal = { ...currentCenter };
    if (isValidLat(center?.lat) && isValidLong(center?.lng)) {
      updatedVal.center = center;
    }
    if (Number.isFinite(zoom)) {
      updatedVal.zoom = zoom;
    }
    setCurrentCenter(updatedVal);
  }, [center, zoom]);

  const handleCameraChange = React.useCallback((cameraChangeEvent) => {
    onCameraChanged(cameraChangeEvent?.detail);
  }, []);

  return (
    <APIProvider apiKey={API_KEY}>
      <Map
        mapId="e92aff408f14a9d"
        defaultCenter={currentCenter?.center}
        defaultZoom={currentCenter?.zoom}
        center={currentCenter?.center}
        zoom={currentCenter?.zoom}
        onCenterChanged={handleCenterChanged}
        onZoomChanged={handleCenterChanged}
        onCameraChanged={handleCameraChange}
        style={{ width: '100%', height: '100%' }}
        gestureHandling="greedy">
        <ClusteredMarkers
          locations={locations}
          activeMarker={activeMarker}
          onMarkerClick={onMarkerClick}
        />
      </Map>
    </APIProvider>
  );
};

export default ({
  locations = [],
  setPreviewId,
  orgId,
  center,
  zoom,
  onCameraChanged,
  setActiveMarkerId
}) => {
  const [preview, setPreview] = React.useState();

  const geoLocations = React.useMemo(() => {
    return locations.filter((a) => {
      return isValidLong(a?.address?.geopoint?.[0]) && isValidLat(a?.address?.geopoint?.[1]);
    });
  }, [locations]);

  return (
    <Wrapper>
      <VisMap
        onCameraChanged={onCameraChanged}
        zoom={zoom}
        center={center}
        locations={geoLocations}
        activeMarker={preview}
        onMarkerClick={(marker) => {
          setActiveMarkerId(marker._id);
          document.getElementById(marker._id)?.scrollIntoView({ block: 'center' });
          setPreview(marker);
        }}
      />
      {preview && (
        <InfoBox
          orgId={orgId}
          location={preview}
          onClose={() => {
            setActiveMarkerId(undefined);
            setPreview(undefined);
          }}
          onClick={() => setPreviewId(preview._id)}
        />
      )}
    </Wrapper>
  );
};
