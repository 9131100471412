import React, { useCallback } from 'react';
import styled from 'styled-components';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

const Bubble = styled.div`
  padding: 10px 12px;
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  &.active {
    background-color: #00a1ff;
  }
`;

export default ({ location, onClick, setMarkerRef, isActive }) => {
  const handleClick = useCallback(() => onClick(location, location._id), []);
  const ref = useCallback((marker) => setMarkerRef(marker, location._id), []);

  return (
    <AdvancedMarker
      position={{ lng: location.address.geopoint[0], lat: location.address.geopoint[1] }}
      ref={ref}
      onClick={handleClick}>
      <Bubble className={isActive ? 'active' : ''}>{location?.displayInfo?.formattedPrice}</Bubble>
    </AdvancedMarker>
  );
};
