import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Section from './section';
import Item from '../../search/list/item';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  .propertyItem {
    height: 250px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .propertyItem {
      height: 300px;
    }
  }
`;

export default ({ nearbyLocations, orgId }) => {
  const navigate = useNavigate();

  if (!nearbyLocations?.length) return <></>;

  return (
    <Section label="NEARBY HOMES" id="nearby">
      <Wrapper>
        {nearbyLocations.map((location, i) => (
          <Item
            key={location._id || i}
            location={location}
            onClick={() => navigate(`/search/${orgId}/${location._id}`)}
          />
        ))}
      </Wrapper>
    </Section>
  );
};
